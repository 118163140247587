<template>
  <div class="block-loader">
    <Loader size="large" />
  </div>
</template>
<script setup>
import Loader from '@/components/atoms/Loader.vue';

import authApi from '@/api/auth';

import {useLocalStorage} from '@/composable/useLocalStorage';

import {saveTokensToStorage} from '@/helpers/tokens';

import router from '@/router';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {onMounted} from 'vue';
import {useRoute} from 'vue-router';

const route = useRoute();

const generateFingerPrint = async () => {
  const fpPromise = await FingerprintJS.load();
  const response = await fpPromise.get();
  return `holanew_${response.visitorId}`;
};

const {setDemoMode} = useLocalStorage();
const loginToDemoMode = async () => {
  const fingerprint = await generateFingerPrint();
  setDemoMode('true');
  const token = await authApi.getTokenForDemo({fingerprint});
  saveTokensToStorage(token);
  try {
    await router.replace({name: 'Business'});
  } finally {
    setTimeout(() => {
      location.reload();
    }, 100);
  }
};

onMounted(() => {
  if (route.query.from) {
    localStorage.setItem('navigatedFrom', route.query.from);
  }
  loginToDemoMode();
});
</script>
<style lang="scss">
.magic-login {
  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  &__error {
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    flex-wrap: wrap;
    text-align: center;
    font-size: 16px;

    &-ico {
      width: 100%;
      color: $red;
      margin-bottom: 5px;

      svg {
        width: 80px;
        height: 80px;
      }
    }

    &-text {
      font-size: 18px;
    }
  }
}
</style>
